<template>
  <div class="text-left">
    <b-form-file
      :value="formFile"
      @input="onInput"
      placeholder="Kies bestand..."
    ></b-form-file>
  </div>
</template>

<script>
  export default {
    name: 'BlobUpload',
    data () {
      return {
        file: null,
        formFile: null
      }
    },
    watch: {

    },
    props: {

    },
    methods: {
      updateFile (file) {
        this.$emit('input', file)
      },
      uploadFile (file) {
        let formData = new FormData()
        formData.append('file', file.rawFile)
        file.status = 'uploading'
        let self = this

        this.$http.post('/temporary-blobs/' + file.sha_hex,
          formData,
          {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: function(progressEvent) {
              var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
              file.uploadPercentage = percentCompleted
              self.updateFile(file)
            }
          }
        ).then(function(response) {
          file.info = response.data.Info
          file.status = 'uploadComplete'
          self.updateFile(file)
        })
        .catch(function(){
          file.status = 'uploadError'
          self.updateFile(file)
        });
      },
      onInput(file) {
        if (file == null) return
        
        let self = this
        let wrapper = {
          rawFile: file
        }

        let reader = new FileReader()
        reader.onload = function (event) {
          crypto.subtle.digest('SHA-384', event.target.result).then(hashBuffer => {
            let hashArray = Buffer.from(new Uint8Array(hashBuffer));
            
            wrapper.sha = hashArray.toString('base64')
            wrapper.sha_hex = hashArray.toString('hex')
            wrapper.status = 'selected'
            wrapper.uploadPercentage = 0
            wrapper.uploadComplete = false
            
            self.uploadFile(wrapper);
          })
        };

        reader.readAsArrayBuffer(file);        
        this.formFile = []
      } 
    },
  }
</script>

<style lang="scss" scoped>

</style>
