var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-left" },
    [
      _c("b-form-file", {
        attrs: { value: _vm.formFile, placeholder: "Kies bestand..." },
        on: { input: _vm.onInput }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }